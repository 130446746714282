import Swiper from "swiper";

var reviewSlider = new Swiper('.js-review-slider', {
    slidesPerView: 1,
    grabCursor: true,
    spaceBetween: 40,
    navigation: {
        nextEl: '.jsSwiper-review-next',
        prevEl: '.jsSwiper-review-prev',
    },
    breakpoints: {
        768: {
            spaceBetween: 12,
        }
    }
});


var benefitsSlider = new Swiper('.js-benefits-slider', {
    slidesPerView: 2,
    grabCursor: true,
    spaceBetween: 40,
    navigation: {
        nextEl: '.jsSwiper-benefits-next',
        prevEl: '.jsSwiper-benefits-prev',
    },
    breakpoints: {
        768: {
            spaceBetween: 12,
        }
    }
});
